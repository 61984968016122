@import 'src/styles/helpers';

.consultation {
  overflow: hidden;
  width: 100vw;
  position: relative;
  padding: 60px 0;

  @include media(landscape) {
    padding: 184px 0 ;
  }

  @include media(desktopAll) {
    padding: 184px 0;
  }

  @include media(landscape) {
    padding: 184px 0 240px;
  }

  @include media(portrait) {
    padding: 120px 0 200px;
  }

  @include media(mobile) {
    max-height: 720px;
    padding: 120px 0 0;
  }
}

.content {
  max-width: 1040px;
  margin: 0 auto;

  @include media(portrait) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include media(mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  &:not(.content_homepage) {
    padding-top: 100px;
  }

  .title, .text {
    text-align: left;
    margin-left: 0;
    margin-right: 0;

    @include media(portrait) {
      text-align: center;
    }

    @include media(mobile) {
      text-align: center;
    }
  }

  .line {
    display: block;
  }
 
}

.text {
  margin-top: 24px;
  margin-bottom: 92px;
  max-width: 410px;

  @include media(desktopHuge) {
    font-size: 20px;
    max-width: none;
  }

  @include media(desktopSmall) {
    margin-bottom: 92px;
  }

  @include media(landscape) {
    margin-top: 32px;
    margin-bottom: 69px;
    max-width: 468px;
  }

  @include media(portrait) {
    margin-bottom: 80px;
  }

  @include media(mobile) {
    margin: 24px 0 80px;
  }
  
  @include media(desktopSmall) {
    margin-bottom: 92px;
  }

  @include media(landscape) {
    margin-top: 32px;
    margin-bottom: 69px;
    max-width: 468px;
  }

  @include media(portrait) {
    margin-bottom: 80px;
  }

  @include media(mobile) {
    margin: 24px 0 80px;
  }
}

.cubes {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;

  @include media(landscape) {
    bottom: 12px;
  }

  @include media(portrait) {
    bottom: -60px;
  }
  
  @include media(mobile) {
    position: initial;
    margin-left: -121px;
  }
}

.line {
  width: 273px;
  height: 18px;
  position: absolute;
  right: calc(-273px + 100px);
  top: calc(100% + 2px);
  display: none;
}

.inputWrapper {
  display: flex;
  width: 100%;
  max-width: 943px;
  justify-content: flex-end;
  margin: 0 auto;

  @include media(landscape) {
    margin-bottom: 30px;
    max-width: 880px;
    padding-right: 64px;
  }

  @include media(portrait) {
    max-width: 100%;
    justify-content: center;
  }

  &__input {
    width: 480px;

    input {
      margin-bottom: 0 !important;
      padding: 0 24px 18px;
    }
  
    @include media(portrait) {
      margin-left: 0;
      width: 480px;
    }
  
    @include media(mobile) {
      width: 100%;
      padding-bottom: 107px;
    }
  }
}