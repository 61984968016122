@import '/src/styles/helpers';

.card {
  @include setProperty(background, linear-gradient(41deg, #141414 0%, #191919 73.83%, #19201F 100%), linear-gradient(41deg, rgba(229, 229, 229, 0.10) 0%, #F5F5F5 73.83%));
  position: relative;
  overflow-x: hidden !important;
  padding: 80px 56px;
  text-align: center;

 

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    background: url('/media/joinUs_before.svg') no-repeat center;
    width: 84px;
    height: 22px;
    right: 73px;
    top: 11px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    background: url('/media/joinUs_after.svg') no-repeat center;
    width: 408px;
    height: 50px;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    position: relative;
    padding: 22px 0;
    z-index: 2;
    display: grid;
    gap: 32px;

    @include media(landscape) {
      padding: 16px 0;
    }

    @include media(portrait) {
      padding: 8px 0;
    }
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(62.5px);
    @include setProperty(background, rgba(25, 32, 31, 0.05), rgba(246, 246, 246, 0.01));
  }

  &__title {
    white-space: pre-line;
    @include setProperty(color, var(--primary-500), var(--primary-900));
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    font-family: 'KharkivTone', sans-serif;

    @include media(portrait) {
      font-size: 24px;
      white-space: initial;
      line-height: 32px;
    }
  }

  &__text {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.183px;
    line-height: 28px;
    margin: 0 auto;

    @include media(portrait) {
      max-width: 442px;
    }
  }

  &__button {
    padding: 15px 24px;
    margin: 0 auto;

    @include media(portrait) {
        padding: 12px 24px;
        height: fit-content;
    }
  }

  @include media(landscape) {
    &__title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: normal;
    }
  }

  @include media(portrait) {
    width: 100vw;
  }

  @include media(mobile) {
    padding: 52px 24px;
    width: 100vw;

    &__content {
      padding: 6px 0px;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__text {
      max-width: 442px;
    }

    &__button {
      padding: 12px 24px;
    }
  }
}