@import '../../../../styles/helpers';

.teamMemberCard {
    display: flex;
    min-width: 246px;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    &__imageWrapper {
        display: flex;
        height: 280px;
        width: 100%;

        & > img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 16px 16px;

        @include media(tablet) {
            padding: 0 16.5px 16px;
        }
    }

    &__name {
        color: var(--secondary-50);
        font-family: 'KharkivTone', sans-serif;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
    }

    &__position,
    &__description{
        color: var(--secondary-200);
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0.183px;
    }

    &__description {
        margin-top: 24px;
        color: var(--secondary-50);
    }
}

.teamMembersWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @include media(desktopSmall) {
        padding: 0 75px !important;
     }
}


.teamMembers {
    display: grid;
    row-gap: 64px;
    column-gap: 24px;
    width: 100%;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax(0, 246px));

    @include media(desktop) {
        max-width: 1200px;
    }

    @include media(desktopBig) {
        max-width: 1280px;
    }   
  
    @include media(desktopHuge) {
        max-width: 1280px;
    }

    @include media(portrait) {
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(0, 246px));
    }

    @include media(mobile) {
        grid-auto-flow: column;  
        grid-template-columns: initial;
        grid-auto-columns: repeat(auto-fit, minmax(246px, 246px));
        overflow: auto;
    }
}