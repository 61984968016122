@import '../../styles/helpers';

.cases {
    display: flex;
    flex-direction: column;
    gap: 140px;
    padding: 144px 0;
    margin: 0 auto;

    @include media(desktop) {
      max-width: 1040px;
    }

    @include media(desktopBig) {
      max-width: 1280px;
    }

    @include media(desktopHuge) {
      max-width: 1280px;
    }

    @include media(desktopSmall) {
      padding: 150px 120px 144px;
    }

    @include media(landscape) {
      padding: 113px 72px 144px;
    }

    @include media(portrait) {
      padding: 120px 64px 120px;
    }

    @include media(mobile) {
      padding: 64px 24px 0;
      gap: 48px;
    }
}

.casesWrapper {
    .cases {
      
      &__content {
        display: grid;
        gap: var(--margin-xxl);
        
        @include media(landscape) {
          gap: 200px;
        }
        
        @include media(desktopAll) {
          gap: 200px;
        }

        @include media(portrait) {
          gap: 64px;
        }

        @include media(mobile) {
          gap: 64px;
        }
      }
    }
  }

.header {
    display: grid;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--margin-xxl);
    grid-template-columns: 377px 520px;

    @include media(landscape) {
      grid-template-columns: 294px 523px;
    }
  
    @include media(portrait) {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: var(--margin-lg);
    }
  
    @include media(mobile) {
      display: flex;
      flex-direction: column;
      gap: var(--margin-lg);
    }

    .title {
        flex-shrink: 0;
    }
      
    .text {
      @include media(portrait) {
        max-width: 520px;
      }
    }
}