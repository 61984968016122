@import 'src/styles/helpers';

.hero {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  padding-top: 400px;
  margin-top: -104px;

  @include media(landscape) {
    padding-top: 359px;
    margin-top: -46px;
  }

  @include media(portrait) {
    padding-top: 404px;
    margin-top: -72px;
  }

  @include media(mobile) {
    padding-top: 339px;
    margin-top: -72px;
  }

  .backgroundImage {

    &__wrapper {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      padding-right: 150px;
      z-index: 1;

      @include media(landscape) {
        padding-right: 79px;
      }

      @include media(portrait) {
        padding-right: 109px;

        img {
          width: 897px;     
          height: 686px;
        }
      }

      @include media(mobile) {
        padding-right: 0;
        padding-left: 38px;
        top: 68px;

        img {
          height: 378px;
          width: 699px;
        }
      }
    }
  }
}

@mixin textSlider() {
  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      @if ($i == 1) {
        transition-delay: 0ms;
      } @else {
        transition-delay: #{($i - 1) * 50}ms;
      }
    }
  }
}

@mixin line {
  opacity: 0;
  transition: all .5s ease;
  transform: translateX(200px);
}

.title {
  font-family: 'KharkivTone', sans-serif;
  font-size: 60px;
  line-height: 80px;  
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  text-transform: uppercase;
  text-align: right;
  padding-bottom: 22px;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;

  @include media(landscape) {
    padding-right: 22px;
    font-size: 36px;
    line-height: 72px;
    padding-bottom: 70px;
  }

  @include media(portrait) {
    font-size: 36px;
    line-height: 60px;
    padding-right: 0;
    padding-bottom: 89px;
  }

   @include media(mobile) {
    font-size: 36px;
    line-height: 52px;
    text-align: center;
    padding: 0 30px 24px;
    width: 100%;

    br {
      display: none;
    }

    @media (max-width: 374px) {
      font-size: 32px;
      line-height: 50px;
      text-align: center;
      padding: 0 12px 24px;
    }
  }

  &.visible {

    &:before, &:after {
      opacity: 1;
      transform: none;
    }
  }

  &__animation {
    position: relative;
    display: inline-block;
  }

  &__image {
    width: 156px;
    height: 42px;

    @include media(desktopHuge) {
      height: 90px;
      width: auto;
    }

    @include media(desktopSmall) {
      margin-bottom: -3px;
    }

    @include media(landscape) {
      width: 140px;
      height: 38px;
      margin-bottom: -7px;
    }

    @include media(portrait) {
      width: 93px;
      height: 26px;
    }

    @include media(mobile) {
      width: 67px;
      height: 28px;
      margin-bottom: -5px;
      display: none;
    }
  }

  &__industries {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 100%;

    &__container {
      overflow: hidden;
    }

    &__letter {
      display: inline-block;
      transform: translate3d(0, 200px, 0);
      will-change: transform;
      transition: none;
    }

    &_up {

      .title__industries__letter {
        transform: translate3d(0, -200px, 0);
        transition: transform 1.15s cubic-bezier(0.6, 0, 0.2, 1);
        @include textSlider();
      }
    }

    &_middle {

      .title__industries__letter {
        transform: translate3d(0, 0, 0);
        transition: transform 1.15s cubic-bezier(0.6, 0, 0.2, 1);
        @include textSlider();
      }
    }
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.ellipse {
  position: absolute;

  &_draw {
    top: -130px;
    left: -131px;
    transition: top 0.5s ease, left 0.5s ease, width 1s ease, transform 2s ease;

    @include media(mobile) {
      left: -196px;
    }

    circle {
      stroke-width: 1px;
    }

    &_moved {
      top: -242px;
      left: -307px;
      transform: rotate(75deg);
    }
  }

  &_border {
    top: 387px;
    left: 345px;
    transition: opacity 1s ease;

    @include media(desktopHuge) {
      left: 640px;
      top: 660px;
    }

    @include media(portrait) {
      left: 525px;
      top: 517px;
    }
  }

  &_gradient {
    left: 367px;
    top: 137px;
    transition: opacity 1s ease;

    @include media(desktopHuge) {
      left: 670px;
      top: 140px;
    }

    @include media(portrait) {
      left: 507px;
      top: 177px;
    }
  }

  &_noise {
    left: 377px;
    top: 259px;
    transition: all 0.5s ease;

    @include media(mobile) {
      left: 150px;
      top: 300px;
    }

    &_moved {
      top: 480px;
      left: 300px;
    }

    @include media(desktopHuge) {
      left: 777px;
      top: 460px;
    }

    @include media(portrait) {
      left: 537px;
      top: 369px;
    }
  }
}

.ctaButton {
  font-size: 24px;
  line-height: 24px;
  width: 242px;
  margin-bottom: 8px;

  @include media(tablet) {
    font-size: 16px;
    line-height: 16px;
    padding: 11px 24px;
    margin-right: 16px;
    width: 244px;
    margin-bottom: 0;
    height: 48px;
  }

  @include media(desktopSmall) {
    width: 370px;
    margin-right: 12px;
  }

  @include media(desktopAll) {
    width: 370px;
    margin-right: 18px;
  }

  @include media(mobile) {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
}
