@import 'src/styles/helpers';

.case {
  display: grid;
  gap: 40px;
  align-items: center;

  &:nth-child(even) {

    @include media(landscape) {
      .image {
        order: 1;
      }
    }

    @include media(desktopAll) {
      .image {
        order: 1;
      }
    }
  }

  @include media(portrait) {
    gap: 24px;
  }

  @include media(landscape) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 64px;
  }

  @include media(desktopAll) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 64px;
  }

  @include media(mobile) {
    gap: 24px;
  }
}

.image {
  display: block;
  @include imgCover;
  height: auto;
  overflow: hidden;
  position: relative;

  img {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.content {
  padding-bottom: 40px;
  display: grid;
  gap: 24px;

  @include media(portrait) {
    padding-bottom: 24px;
  }

  @include media(landscape) {
    padding: 24px 0;
  }

  @include media(desktopAll) {
    padding: 24px 0;
  }

  @include media(mobile) {
    padding-bottom: 24px
  }
}

.projectName {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  font-size: 16px;
  line-height: 20px;
  letter-spacing: var(--ls-183);
  text-transform: uppercase;
  margin-bottom: var(--margin-xs);
  font-weight: 600;
  
  @include media(tablet) {
    margin-bottom: 12px;
  }

  @include media(desktopAll) {
    margin-bottom: 12px;
  }

  @include media(mobile) {
    margin-bottom: 12px;
  }
}

.title {
  display: inline-block;
  font-family: 'KharkivTone', sans-serif;
  font-size: 32px;
  line-height: 40px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  @include media(tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  @include media(mobile) {
    font-size: 24px;
    line-height: 32px;
  }
}

.description {
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 0.183px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  @include media(desktopHuge) {
    -webkit-line-clamp: 4;
  }

  @include media(desktopBig) {
    -webkit-line-clamp: 4;
  }

  @include media(landscape) {
    -webkit-line-clamp: 7;
    font-size: 14px;
    line-height: 28px;
  }

  @include media(portrait) {
    font-size: 14px;
    line-height: 28px; 
    -webkit-line-clamp: 4;
  }

  @include media(mobile) {
    padding-top: 8px;
    font-size: 14px;
    line-height: 28px; 
    letter-spacing: normal;
    -webkit-line-clamp: 8;
  }
}

.technologies {

  &__title {
    @include setProperty(color, var(--secondary-100), var(--secondary-600));
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-md);
  }

  .technology {
    flex-shrink: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
    }
  }
}

.viewButton {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  display: inline-block;
  cursor: pointer;
  margin-left: -24px;
  font-size: 16px;
  line-height: 1;
  font-family: 'KharkivTone', sans-serif;
}
