@import "../../../../../styles/helpers";

.review {
  position: relative;
}

.video {
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.preview {
  cursor: pointer;
}

.author {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  width: 100%;
  color: var(--secondary-50);

  @include media(desktopHuge) {
    gap: 36px;
    padding: 37px 55px;

    &__avatar {
      width: 110px;
      height: 110px;
    }

    &__name {
      font-size: 37px;
      line-height: 45px;
    }

    &__position {
      font-size: 27px;
      line-height: 33px;
    }
  }

  @include media(mobile) {
    gap: 5px;
  }
}
