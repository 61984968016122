@import 'src/styles/helpers';

.clients {
  overflow: hidden;
  width: 100vw;
  padding: 120px 0 124px;

  @include media(tablet) {
    padding: 120px 0;
  }

  @include media(desktopAll) {
    padding: 162px 0 120px;
  }

  @include media(mobile) {
    padding: 100px 0 124px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 64px;
    width: auto;
    position: relative;
    z-index: 1;

    @include media(landscape) {
      gap: 64px;
    }

    @include media(portrait) {
      gap: 40px;
    }

    @include media(mobile) {
      gap: 40px;
    }

    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, var(--bg) 2.74%, transparent 54.55%, var(--bg) 100%);
      z-index: 2;
    }
  }
}

.row {
  display: flex;
  justify-content: center;
  gap: 104px;
  transition: none;
  max-height: 46px;

  @include media(portrait) {
    gap: 40px;
  }

  @include media(mobile) {
    gap: 30px;
  }
}

.item {
  max-width: 310px;
  min-width: 180px;
  display: flex;
  max-height: 46px;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  object-fit: cover;

  @include media(mobile) {
    max-height: 25px;
    min-width: fit-content;
    max-width: 90px;
  }

  svg, img {
    width: 100%;
    height: 100%;
  }
}

.header {
  margin-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;

  @include media(desktopAll) {
    margin-bottom: 120px;
    gap: var(--margin-lg);

    .ctaButton {
      display: flex;
    }
  }
  
  @include media(desktop) {
    max-width: 1040px;
  }

  @include media(desktopBig) {
    max-width: 1280px;
  }

  @include media(desktopHuge) {
    max-width: 1280px;
  }

  @include media(landscape) {
    margin-bottom: 120px;
  }

  @include media(portrait) {
    margin-bottom: 120px;
    gap: 8px;
  }

  @include media(mobile) {
    gap: 8px;
  }

  .ctaButton {
    font-size: 16px;
    line-height: 16px; 
    white-space: nowrap;
    gap: 8px;

    @include media(mobile) {
      display: none;
    }
  }

  .divider {
    display: block;
    height: 1px;
    @include setProperty(background, var(--secondary-500), var(--secondary-100));
    width: 100%;
  }
}

.ctaButton {
  font-size: 16px;
  line-height: 16px; 
  white-space: nowrap;
  @include setProperty(color, var(--primary-500), var(--primary-900));
  @include setProperty(border-color, var(--primary-500), var(--primary-900));
  font-family: 'KharkivTone', sans-serif;
  margin: 0 auto;

  &_mobile {
    margin-top: 40px;

    @include media(tablet) {
      display: none !important;
    }

    @include media(desktopAll) {
      display: none !important;
    }

    @include media(mobile) {
     display: flex;
     margin-top: 72px;
    }
  }

  @include media(portrait) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media(desktopAll) {
    padding-top: 16px;
    padding-bottom: 14px;
  }
}
