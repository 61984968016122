@import '../../../styles/helpers';

.expertises {
  position: relative;
  padding: 32px 0 64px;
  display: flex;
  justify-content: center;

  @include media(desktopAll) {
    padding: 120px 0 64px;
  }

  @include media(desktop) {
    max-width: 1040px;
  }
  
  @include media(desktopBig) {
    max-width: 1280px;
  }

  @include media(desktopHuge) {
    max-width: 1040px;
  }

  .graphElementImageBottom {
    position: absolute;
    left: -120px;
    top: 240px;

    @include media(desktopBig) {
      left: -241px;
      top: 242px;
    }

    @include media(desktopHuge) {
      left: -241px;
      top: 242px;
    }

    @include media(desktopSmall) {
      left: 26px;
      top: 248px;
    }

    @include media(landscape) {
      left: 76px;
      top: 208px;
    }

    @include media(mobile) {
      display: none;
    }
  }

  .graphElementImageTop {
    position: absolute;
    top: 100px;
    left: 250px;

    @include media(tablet) {
      top: 40px;
      left: 400px;
     }

    @include media(mobile) {
      top: 20px;
      left: 400px;
    }
  }

  &__ellipses {
    position: absolute;
    left: -180px;
    top: -140px;
    z-index: 0;
  }

  &__column {
    position: relative;
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      border-right: 1px solid;
      @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
    }

    .graphElementImage {
      position: absolute;
      left: -316px;
      bottom: -256px;
  
      @include media(desktopBig) {
        left: -315px;
        bottom: -384px;
      }
  
      @include media(desktopHuge) {
        left: -315px;
        bottom: -384px;
      }
  
      @include media(desktopSmall) {
        left: -315.007px;
        bottom: -256.379px;
      }
  
      @include media(landscape) {
        left: -300px;
        bottom: -316px;
      }

      @include media(tablet) {
        display: none
      }
  
      @include media(mobile) {
        display: none
      }
    }

    .graphElementImageSmall {
      transform: rotate(-108.047deg);
      position: absolute;
      left: 165px;
      bottom: 50px;
    }
  }

  &__title {
    margin-right: auto;
    margin-bottom: 159px;
    margin-left: 3px;
    max-width: 341px;
    font-size: 32px;
    line-height: 40px;
    @include setProperty(color, var(--primary-50), var(--primary-500));
    font-family: 'KharkivTone', sans-serif;

    mark {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }

    @include media(landscape) {
      margin-bottom: 209px;
    }
  }

  @include media(landscape) {
    // padding: 0 72px 64px;
    padding: 120px 72px 64px;
  }

  @include media(tablet) {

    &__title {
      font-size: 24px;
      line-height: 32px;
    }
  }

  @include media(portrait) {
    padding: 120px 0 0 ;
    flex-direction: column;
    align-items: center;

    &__column {
      width: 100%;
      flex: auto;
      display: flex;
      align-items: center;

      &:first-of-type {
        border: none;
      }
    }

    &__title {
      margin-bottom: 64px;
    }
  }

  @include media(mobile) {
    padding: 120px 0 0 ;
    width: 100%;
    flex-direction: column;
    align-items: center;

    &__column {
      width: 100%;
      flex: auto;
      display: flex;
      align-items: center;

      &:first-of-type {
        border: none;
      }
    }

    &__title {
      margin-left: 0;
      margin-bottom: 64px;
      max-width: 300px;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.expertiseCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 24px;
  padding: 80px 56px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 1px;
    @include setProperty(background, var(--dark-line-low-accent), var(--light-line-low-accent));
  }

  &__icon {
    position: relative;
    height: 120px;
    width: 300px;
  }

  &__title {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 16px;
    line-height: 2;
    font-weight: 300;
    letter-spacing: 0.183px;
  }

  @include media(tablet) {
    &__title {
      font-size: 16px;
      line-height: 24px;
    }

    &__text {
      font-size: 14px;
      letter-spacing: normal;
    }
  }

  @include media(portrait) {
    gap: 16px;
    padding: 48px 56px;
    max-width: 520px;

    &__title {
      margin-bottom: 0;
    }

    &:after {
      width: calc(100vw - 64px * 2);
    }
  }


  @include media(mobile) {
    gap: 16px;
    padding: 48px 0;
    justify-content: center;

    &__title {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 24px;
    }

    &__text {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: normal;
    }
  }
}