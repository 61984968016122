@import '../../../../styles/helpers';

.review {
  margin: 112px auto 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 40px;
  padding: 64px 0 80px;

  @include media(desktop) {
    max-width: 1040px;
  }

  @include media(desktopHuge) {
    max-width: none;
    width: 1760px;
    margin: 24px auto 0;
  }

  @include media(desktopBig) {
    max-width: 1280px;
    margin: 24px auto 0;
  }

  @include media(desktopSmall) {
    margin: 55px 0 0;
    padding-left: 72px;
    padding-right: 72px;
  }

  @include media(landscape) {
    margin: 55px 0 0;
    padding-left: 72px;
    padding-right: 72px;
  }

  @include media(portrait) {
    padding-top: 24px;
    display: grid;
    justify-content: center;
    text-align: center;
    gap: 24px;
    padding-bottom: 48px;
  }

  @include media(portrait) {
    padding-bottom: 41px;
  }
 

  @include media(mobile) {
    display: grid;
    justify-content: center;
    text-align: center;
    gap: 24px;
    padding: 100px 24px 0;
    margin-top: 150px;
  }
}

.content {
  width: 470px;

  @include media(desktopHuge) {
    width: 600px;
  }

  @include media(portrait) {
    width: 100%;
    max-width: 640px;
  }

  @include media(mobile) {
    width: 100%;
  }
}

.clutchBg {
  position: absolute;
  top: 144px;
  left: 10px;
  width: 720px;
  pointer-events: none;

  @include media(desktopHuge) {
    width: 1200px;
    top: 0;
  }

  @include media(landscape) {
    left: 85px;
  }

  @include media(portrait) {
    top: -72px;
    width: 519px;
    left: -45px;
  }

  @include media(mobile) {
    top: 0;
    width: 520px;
    left: -80px;
  }
}

.logo {
  width: 198px;
  height: auto;

  @include media(desktopHuge) {
    width: 320px;
  }

  @include media(portrait) {
    margin: 0 auto;
    padding: 12px 0;
    width: 172px;
  }

  @include media(mobile) {
    padding: 12px 0;
    margin: 0 auto;
    width: 172px;
  }
}

.stars {
  margin-top: 8px;
  margin-bottom: 12px;
  gap: 1px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include setProperty(color, var(--primary-500), var(--primary-900));

  @include media(desktopHuge) {
    margin-bottom: 16px;
  }

  @include media(portrait) {
    justify-content: center;
  }

  @include media(mobile) {
    justify-content: center;
  }

  .star {
    width: 16px;
    height: 16px;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    @include media(portrait) {
      width: 13px;
      height: 13px;
    }

    @include media(mobile) {
      width: 13px;
      height: 13px;
    }
  }
}

.rating {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0.183px;

  @include media(landscape) {
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: normal;
  }

  &__link {
    font-weight: 500;
    text-decoration: underline;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    &:hover {
      text-decoration: none;
    }
  }
}

.text {
  @include setProperty(font-weight, 300, 500);
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.183px;
  margin-top: 40px;

  @include media(tablet) {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: normal;
  }

  @include media(mobile) {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: normal;
  }
}
