@import '../../../styles/helpers';

.about {
  position: relative;
  transition: none;
  display: grid;
  padding: 120px 0;

  @include media(mobile) {
    padding: 128px 0 0;
    gap: 56px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 120px;
    @include setProperty(background, var(--dark-bg), var(--light-bg));
    position: relative;
    overflow: hidden;

    @include media(mobile) {
      gap: 64px;
    }
  }

  .ellipse {
    position: absolute;
    left: -91px;
    bottom: -198px;

    @include media(landscape) {
      left: 72px;
      bottom: 482px;
    }

    @include media(portrait) {
      display: none;
    }

    @include media(mobile) {
      display: none;
    }
  }
}

.header {
  display: grid;
  align-items: flex-start;
  justify-content: space-between;
  gap: 64px;
  grid-template-columns: 367px 520px;

  @include media(desktop) {
    max-width: 1040px;
  }

  @include media(desktopBig) {
    max-width: 1280px;
  }

  @include media(desktopHuge) {
      max-width: 1280px;
  }

  @include media(landscape) {
    grid-template-columns: 297px 521px;
  }

  @include media(portrait) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  @include media(mobile) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}

.title {
  flex-shrink: 0;
  white-space: pre-line;
}

.additionalInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 96px;
  width: 100%;
  align-items: center;

  .textContent {
    display: grid;
    grid-template-columns: 500px 396px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 64px;
    width: 100%;
  
    @include media(desktop) {
      max-width: 1040px;
    }
  
    @include media(desktopBig) {
      max-width: 1280px;
    }
  
    @include media(desktopHuge) {
      max-width: 1280px;
    }

    @include media(landscape) {
      grid-template-columns: 513px 300px;
      gap: initial;
    }

    @include media(portrait) {
      display: flex;
      flex-direction: column-reverse;
      gap: 32px;
      margin: 0 56px;
    }

    @include media(mobile) {
      display: flex;
      flex-direction: column-reverse;
      gap: 32px;
      margin: 0 56px;
    }

    .text {

      @include media(tablet) {
        font-size: 16px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: 0.183px;
      }

      @include media(mobile) {
        font-size: 16px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: 0.183px;
      }
    }
  }
  
  .slogan {
    padding: 24px 0;
    font-size: 20px;
    line-height: 28px;
    font-family: 'KharkivTone', sans-serif;
    font-size: 24px;
    line-height: 32px;

    @include media(landscape) {
      padding: 8px 0;
    }

    @include media(portrait) {
      padding: 0;
      max-width: 400px;
    }

    @include media(mobile) {
      padding: 0;
    }
  }
  
  .ctaButton {
    position: relative;
    z-index: 2;
    font-family: 'KharkivTone', sans-serif;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;

    @include media(tablet) {
      font-size: 16px;
      line-height: 16px;
    }

    @include media(mobile) {
      font-size: 16px;
      line-height: 16px;
    }

    svg {
      min-width: 32px;
      min-height: 32px;
    }
  }
  
  .blurBg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    width: 100%;
    @include setProperty(background, var(--primary-500), var(--primary-900));
    filter: blur(54px);

    @include media(mobile) {
      display: none;
    }
  }
}