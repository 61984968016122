@import '../../../../styles/helpers';

.reviews {
  position: relative;
  z-index: 1;
  margin: 64px auto 0;

  @include media(tablet) {
    margin: 120px 0;
  }

  @include media(desktopAll) {
    margin: 120px 0;
  }

  :global {
    .splide__slide {

      &:not(.is-active) {
        opacity: 0.3;
        pointer-events: none;
      }

    }
  }

  > div {
    height: 360px;

    @include media(desktopHuge) {
      height: 832px;
    }

    @include media(portrait) {
      height: 222px;
    }

    @include media(mobile) {
      width: 100%;
      height: auto;
      aspect-ratio: 2 / 1;
    }
  }

  * {
    transition: none;
  }
}

$review-width: 728px;
$review-width-desktopHuge: 1682px;
$review-width-tablet: 450px;
$review-width-mobile: calc(100vw - 64px);

.review {
  width: $review-width;
  height: 360px;

  @include media(desktopHuge) {
    height: 832px;
    width: $review-width-desktopHuge;
  }

  @include media(portrait) {
    width: $review-width-tablet;
    height: 222px;
  }

  @include media(mobile) {
    width: $review-width-mobile;
    height: auto;
    aspect-ratio: 2 / 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  background-color: transparent;
  width: 136px;
  border-radius: 0;
  display: none;
  cursor: default;

  @include media(desktopAll) {
    display: block;
    width: 136px;
    height: 94px;
  }

  @include media(desktopHuge) {
    width: 314px;
    height: 217px;
  }

  &_prev {
    left: calc(50% - $review-width / 2);
    transform: translate(calc(-100% - 30px), -50%);

    @include media(desktopHuge) {
      left: calc(50% - $review-width-desktopHuge / 2);
    }

    img {
      transform: rotate(180deg)
    }
  }

  &_next {
    right: calc(50% - $review-width / 2);
    transform: translate(calc(100% + 30px), -50%);

    @include media(desktopHuge) {
      right: calc(50% - $review-width-desktopHuge / 2);
    }
  }
}
