@import 'src/styles/helpers';

.reviews {
  width: 100vw;
  padding: 120px 0;

  &__content {
    position: relative;

    @include media(landscape) {
      padding-bottom: 48px;
    }
  }

  @include media(portrait) {
    padding: 120px 0;
  }

  @include media(landscape) {
    padding: 100px 0 0;
  }

  @include media(desktopAll) {
    padding: 120px 0;
  }

  @include media(mobile) {
    padding: 88px 0 32px;
  }

  &__header {
    margin: 0 auto;

    @include media(desktop) {
      max-width: 1040px;
    }

    @include media(desktopBig) {
      max-width: 1280px;
    }

    @include media(desktopSmall) {
      padding: 0 72px;
    }

    @include media(landscape) {
      padding: 0 72px;
    }

    @include media(desktopHuge) {
      max-width: 1280px;
    }

    @include media(portrait) {
      padding: 0 64px;
    }

    @include media(mobile) {
      padding: 0 24px;
    }
  }

  .text {
    margin-top: 11px;
    max-width: 724px;
  
    @include media(desktopBig) {
      max-width: 725px;
    }
  
    @include media(desktopHuge) {
      max-width: 725px;
    }

    @include media(landscape) {
      margin-top: 24px;
    }

    @include media(portrait) {
      margin-top: 32px;
    }
  
    @include media(mobile) {
      margin-top: 32px;
    }
  }
}


.ellipse {

  &_right,
  &_left {
    position: absolute;
    bottom: 0;
    z-index: 0;
    pointer-events: none;
    display: none;
    width: 378px;
    height: 276px;


    border-radius: 376.989px;
    opacity: 0.14;
    background: var(--primary-400);
    mix-blend-mode: screen;
    filter: blur(116.5px);
    transform: rotate(153.302deg);
  }

  &_right {
    bottom: 150px;
  }

  @include media(desktopSmall) {

    &_right,
    &_left {
      display: block;
    }

    &_right {
      right: -120px;
    }

    &_left {
      left: -100px;
    }
  }

  @include media(desktop) {

    &_right,
    &_left {
      display: block;
    }

    &_right {
      right: -250px;
    }

    &_left {
      left: -200px;
    }
  }

  @include media(desktopBig) {

    &_right,
    &_left {
      display: block;
    }

    &_right {
      right: -120px;
    }

    &_left {
      left: -100;
    }
  }

  @include media(desktopHuge) {

    &_right,
    &_left {
      display: block;
    }

    &_right {
      right: 5%;
    }

    &_left {
      left: 5%;
    }
  }

}