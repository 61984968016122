@import '../../styles/helpers';

.main {
  @include media(tablet) {
    overflow: hidden;
  }

  @include media(mobile) {
    overflow: hidden;
  }
}

.section {
  position: relative;
}


