@import 'src/styles/helpers';

.form {
  position: relative;

  :global {

    .MuiFormControl-root {
      margin: 0 !important;

      .MuiFormHelperText-root {
        font-family: 'Montserrat', sans-serif !important;
        position: absolute !important;
        top: 100% !important;
        bottom: auto !important;
        left: var(--margin-md) !important;
        @include setProperty(color, var(--dark-error) !important, var(--light-error) !important);
      }
    }

    .MuiInputLabel-root {
      color: var(--secondary-200) !important;
      left: 24px !important;
      right: 52px !important;
      font-family: 'Montserrat', sans-serif !important;
      font-size: 14px !important;
      line-height: 2 !important;
      font-weight: 300 !important;
      letter-spacing: var(--ls-183) !important;

      &.Mui-focused {
        @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
      }
    }

    .MuiInput-input {
      @include setProperty(color, var(--secondary-100) !important, var(--secondary-400) !important);
      font-family: 'Montserrat', sans-serif !important;
      font-size: 14px !important;
      line-height: 2 !important;
      font-weight: 300 !important;
      padding: 0 var(--margin-md) !important;
      letter-spacing: 0.4px !important;

      &:not(.MuiInputBase-inputMultiline) {
        height: 40px !important;
      }

      &.MuiSelect-select {
        line-height: 40px !important;
      }
    }

    .MuiInputBase-root {

      &.Mui-focused {

        .MuiSelect-select {
          background: transparent !important;
        }
      }

      :local {
        .dropdownIcon {
          width: 24px;
          height: 24px;
          right: 24px;
          position: absolute;
          top: 2px;
          pointer-events: none;
        }
      }
    }

    .MuiInputBase-inputMultiline {
      min-height: 40px !important;
    }

    .MuiTextField-root {
      height: auto !important;

      &:hover {
        .MuiInputLabel-root:not(.Mui-focused) {
          @include setProperty(color, var(--secondary-100) !important, var(--secondary-400) !important);
        }
      }
    }

    .MuiInput-root {

      &:before {
        border-bottom: 1px solid var(--secondary-200) !important;
      }

      &:hover {

        &:before {
          @include setProperty(border-color, var(--secondary-100) !important, var(--secondary-400) !important);
        }
      }

      &.Mui-error {

        &:before, &:after {
          @include setProperty(border-bottom, 1px solid var(--dark-error) !important, 1px solid var(--light-error) !important);
        }
      }
    }
  }
}

.submitButton {
  display: flex;
  position: absolute;
  top: 20px;
  right: 24px;
  @include setProperty(color, var(--secondary-300), var(--secondary-200));
  width: 24px;
  height: 24px;
  transition: color 0.3s ease;

  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @include media(desktopAll) {
    order: 2;
    margin-left: auto;
    grid-column: 2 / 3;
  }

  .spinner {
    width: 24px;
    height: 24px;
    animation: spin 1.5s linear infinite;
  }

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.input {
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
}

.status {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: var(--ls-183);
  order: 1;
  margin-top: 32px;

  &.success {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &.error {
    @include setProperty(color, var(--dark-error), var(--light-error));
  }
}
