@import 'src/styles/helpers';

.slide {
  padding: 0 var(--margin-lg);
  height: 100%;

  @include media(portrait) {
    padding: 0 80px;
  }

  @include media(landscape) {
    padding: 0 120px;

    &__content {
      max-width: 596px;
      margin: 0 auto;
    }
  }

  @include media(desktopAll) {

    &__content {
      max-width: 596px;
      margin: 0 auto;
    }
  }

  @include media(desktopSmall) {
    padding: 0 120px;
  }

  @include media(desktop) {
    padding: 0 200px;
  }

  @include media(desktopBig) {
    padding: 0 320px;
  }

  @include media(desktopHuge) {
    padding: 0 560px;
  }
}

.text {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 48px;
  text-align: center;

  @include media(mobile) {
    margin-bottom: 56px;
  }

  @include media(landscape) {
    margin-bottom: 100px;
    text-align: left;
    font-size: 16px;
    line-height: 32px;
  }

  @include media(desktopAll) {
    text-align: left;
    margin-bottom: 100px;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.183px;
  }
}

.author {
  margin-bottom: 56px;
  text-align: center;

  @include media(landscape) {
    display: grid;
    align-items: center;
    gap: 24px;
    grid-template-columns: auto 1fr auto;
  }

  @include media(portrait) {
    margin-bottom: 48px;
  }

  @include media(desktopAll) {
    display: grid;
    align-items: center;
    gap: 24px;
    grid-template-columns: auto 1fr auto;
  }

  @include media(mobile) {
    margin-bottom: 48px;
  }

  &__avatar {
    @include imgCover;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto var(--margin-sm);

    @include media(landscape) {
      margin-bottom: 0;
      width: 100px;
      height: 100px;
    }

    @include media(desktopAll) {
      margin-bottom: 0;
      width: 100px;
      height: 100px;
    }
  }

  &__info {

    @include media(landscape) {
      text-align: left;
    }

    @include media(desktopAll) {
      text-align: left;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 24px;
    font-family: 'KharkivTone', sans-serif;
    margin-bottom: var(--margin-xs);

    @include media(desktopAll) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(desktopSmall) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__description,
  &__linkedinLink {
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;

    @include media(mobile) {
      letter-spacing: 0.48px;
    }
  }

  &__linkedinLink {
    color: var(--primary-500);
    text-decoration: underline;
    cursor: pointer;
  }

  &__clutch {
    margin: var(--margin-md) auto 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 200px;
    font-style: italic;
    font-size: 14px;
    line-height: 1;
    @include setProperty(background, var(--secondary-800), var(--secondary-50));
    @include setProperty(color, var(--secondary-50), var(--secondary-500));

    &:hover {
      @include setProperty(background, var(--secondary-600), var(--secondary-100));
    }

    &:active {
      @include setProperty(background, var(--secondary-700), var(--secondary-50));
      @include setProperty(color, var(--secondary-50), var(--secondary-300));
    }

    @include media(landscape) {
      margin-top: 0;
    }

    @include media(portrait) {
      height: 48px;
    }

    @include media(mobile) {
      height: 48px;
    }

    @include media(desktopAll) {
      margin-top: 0;
    }
  }
}

.case {
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 0;
  right: 0;
  padding: 0 var(--margin-lg);
  font-size: 14px;
  line-height: 2;

  @include media(landscape) {
    text-align: left;
    padding: 0;
    width: 596px;
    margin: 0 auto;
  }

  @include media(desktopAll) {
    text-align: left;
    padding: 0;
    width: 596px;
    margin: 0 auto;
  }

  a {
    @include setProperty(color, var(--primary-500), var(--primary-900));
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}