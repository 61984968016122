@import 'src/styles/helpers';

.reviews {
  margin: 255px auto 0;
  position: relative;

  @include media(portrait) {
    padding: 0 0 124px;
    margin: 248px auto 0;
  }

  @include media(landscape) {
    padding-bottom: 232px;
    margin-top: 380px;
  }

  @include media(desktopSmall) {
    padding: 200px 0 232px;
  }

  @include media(desktop) {
    padding: 200px 0 232px;
  }

  @include media(desktopBig) {
    padding: 200px 0 320px;
  }

  @include media(desktopHuge) {
    padding: 200px 0 200px;
  }

  @include media(mobile) {
    margin: 140px auto 0;
  }

}

.slider {
  width: 100%;
  padding-bottom: 56px;

  @include media(landscape) {
    padding-bottom: 0;
  }

  @include media(desktopAll) {
    padding-bottom: 0;
  }

  .arrow {
    background-color: transparent;
    border-radius: 0;
    transform: none;
    top: auto;
    outline: none !important;

    &_next {
      width: 93px;
      height: 65px;
      right: -14px;
      bottom: 35%;

      @include media(tablet) {
        width: 130px;
        height: 94px;
      }

      @include media(portrait) {
        bottom: -48px;
        right: 80px;
      }

      @include media(landscape) {
        right: 120px;
        bottom: 50%;
        transform: translateY(50%);
      }

      @include media(desktopAll) {
        width: 130px;
        height: 94px;
        right: 200px;
        bottom: 50%;
        transform: translateY(50%);
      }

      @include media(desktopSmall) {
        right: 120px;
      }

      @include media(desktopBig) {
        right: 320px;
      }

      @include media(desktopHuge) {
        width: 240px;
        height: 166px;
        right: 500px;
      }
    }

    &_prev {
      width: 46px;
      height: 33px;
      bottom: 27%;

      @include media(portrait) {
        top: -18px;
        bottom: auto;
        left: calc(21% - 20px);
        width: 52px;
        height: 37px;
      }

      @include media(landscape) {
        width: 108px;
        height: 74px;
        left: 120px;
        bottom: 0;
      }

      @include media(desktopAll) {
        width: 108px;
        height: 74px;
        left: 200px;
        bottom: -37px;
      }

      @include media(desktopSmall) {
        left: 120px;
      }

      @include media(desktopBig) {
        left: 320px;
      }

      @include media(desktopHuge) {
        left: 500px;
        width: 155px;
        height: 106px;
      }

      img {
        transform: rotate(180deg);
      }
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  bottom: 0;
  padding: 0;

  @include media(landscape) {
    justify-content: flex-end;
    width: 596px;
    margin: 0 auto;
    height: 28px;
  }

  @include media(desktopAll) {
    justify-content: flex-end;
    width: 596px;
    margin: 0 auto;
    height: 28px;
  }

  &__item {
    @include setProperty(background, url('/media/elps_border_dark.svg') no-repeat center / contain, url('/media/elps_border_light.svg') no-repeat center / contain);
    width: 16px;
    height: 16px;
    margin: 0;

    &:global {

      &.is-active {
        // transform: none;
        transform: rotate(90deg);
        @include setProperty(background, var(--dark-elipse-gradient), var(--light-elipse-grain));
      }
    }
  }
}

.parallax {

  &__draw {
    position: absolute;
    left: -10%;
    top: -100px;
    transform: rotate(100deg);

    @include media(portrait) {
      left: 0;
      top: -65px;
    }

    @include media(landscape) {
      left: -10%;
      top: -100px;
    }

    @include media(desktopSmall) {
      left: 15%;
      top: -120px;
    }

    @include media(desktop) {
      left: 20%;
      top: -100px;
    }

    @include media(desktopBig) {
      left: 30%;
      top: -40px;
    }

    @include media(desktopHuge) {
      left: 35%;
      top: -40px;
    }
  }

  &__noise {
    position: absolute;
    left: -10px;
    top: -50px;

    @include media(portrait) {
      opacity: 1;
      left: 20px;
    }

    @include media(landscape) {
      top: 10px;
      opacity: 0.1;
    }

    @include media(desktopSmall) {
      left: 64%;
      top: 10px;
      opacity: 0.1;
    }

    @include media(desktop) {
      left: 64%;
      top: 10px;
      opacity: 0.1;
    }

    @include media(desktopBig) {
      left: 64%;
      top: 10px;
      opacity: 0.1;
    }

    @include media(desktopHuge) {
      left: 68%;
      top: 10px;
      opacity: 0.1;
    }
  }

  &__borderNoise {
    position: absolute;
    right: -10px;
    opacity: .5;
    top: 10px;

    @include media(portrait) {
      right: 53px;
      top: 14px;
      display: none;
    }

    @include media(landscape) {
      right: 53px;
      top: 14px;
    }

    @include media(desktopSmall) {
      left: 64%;
      top: 10px;
    }

    @include media(desktop) {
      left: 64%;
      top: 10px;
    }

    @include media(desktopBig) {
      left: 64%;
      top: 10px;
    }

    @include media(desktopHuge) {
      left: 68%;
      top: 10px;
    }
  }

  &__border {
    position: absolute;
    opacity: 0.5;
    right: 10px;
    bottom: 40%;

    @include media(portrait) {
      right: 40%;
    }

    @include media(landscape) {
      left: 120px;
      bottom: 20%;
    }

    @include media(desktopSmall) {
      left: 120px;
      bottom: 20%;
    }

    @include media(desktop) {
      left: 25%;
      bottom: 30%;
    }

    @include media(desktopBig) {
      left: 25%;
      bottom: 30%;
    }

    @include media(desktopHuge) {
      left: 30%;
      bottom: 15%;
    }
  }

  &__gribs {
    position: absolute;
    right: -70px;
    top: -90px;
    display: none;

    @include media(desktopSmall) {
      display: block;
    }

    @include media(desktop) {
      display: block;
    }

    @include media(desktopBig) {
      display: block;
    }

    @include media(desktopHuge) {
      display: block;
    }
  }

  &__graph {
    position: absolute;
    bottom: -23px;
    right: 28%;
    display: none;


    @include media(desktopSmall) {
      display: block;
      right: 100px;
    }

    @include media(desktop) {
      display: block;
    }

    @include media(desktopBig) {
      display: block;
    }

    @include media(desktopHuge) {
      display: block;
    }
  }

  &__cube {
    position: absolute;
    left: 14%;
    top: 34px;
    display: none;

    @include media(desktopSmall) {
      display: block;
      left: 8%;
      top: 15px;
    }

    @include media(desktop) {
      display: block;
    }

    @include media(desktopBig) {
      display: block;
    }

    @include media(desktopHuge) {
      display: block;
    }
  }

  &__gradient {
    position: absolute;
    left: 28%;
    top: -18px;
    display: none;
    transform: rotate(90deg);
    opacity: .5;

    @include media(desktopSmall) {
      display: block;
      left: 20%;
    }

    @include media(desktop) {
      display: block;
    }

    @include media(desktopBig) {
      display: block;
    }

    @include media(desktopHuge) {
      display: block;
    }
  }
}