@import '../../../styles/helpers';

.techStackWrapper {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
  
    opacity: 0.14;
    background: var(--primary-400);
    border-radius: 432.648px;
    filter: blur(116.5px);
  }

  &::before {
    width: 432px;
    height: 320px;
    left: -340px;
    top: -174px;
    transform: rotate(153.302deg);
  }

  &::after {
    width: 498.591px;
    height: 368.684px;
    right: -445.08px;
    bottom: -303.388px;
    transform: rotate(153.302deg);
  }
}

.techStack {
  padding: 120px 0;
  width: 100%;
  margin: 0 auto;

  @include media(desktop) {
    max-width: 1040px;
  }

  @include media(desktopBig) {
    max-width: 1280px;
  }

  @include media(desktopHuge) {
      max-width: 1280px;
  }

  @include media(mobile) {
    padding: 184px 0 120px;
  }



  &__header {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 400px 520px;
    gap: 64px;
    align-items: flex-start;

    .graphElementImageTitle {
      position: absolute;
      left: 397px;
      top: 218px;    
    }

    @include media(landscape) {
      grid-template-columns: 230px 520px;
    }
  
    @include media(portrait) {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  
    @include media(mobile) {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  .graphElementImageBottom {
    position: absolute;
    bottom: -124px;
    right: 116px;

    @include media(desktopBig) {
      bottom: -260px;
      right: 600px;
    }

    @include media(desktopHuge) {
      bottom: -260px;
      right: 600px;
    }

    @include media(portrait) {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 120px;

  @include media(portrait) {
    gap: 96px;
  }

  @include media(mobile) {
    gap: 96px;
  }

  &__wrap {
    margin-top: 140px;

    @include media(landscape) {
      margin-top: 140px;
    }

    @include media(portrait) {
      margin-top: 96px;
    }

    @include media(mobile) {
      margin-top: 96px;
    }
  }
}

.row {
  display: grid;
  width: fit-content;
  grid-template-columns: 272px minmax(0, 1fr);
  gap: 120px;

  @include media(landscape) {
    grid-template-columns: 272px minmax(0, 1fr);
  }

  @include media(portrait) {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  @include media(mobile) {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__title {
    @include setProperty(color, var(--secondary-300), var(--secondary-500));
    font-size: 32px;
    line-height: normal;
    
    @include media(tablet) {
      font-size: 24px;
      line-height: 32px;
      font-family: 'KharkivTone', sans-serif;
    }

    @include media(landscape) {
      max-width: 200px;
    }

    @include media(mobile) {
      font-size: 24px;
      line-height: 32px;
      font-family: 'KharkivTone', sans-serif;
    }
  }

  &__technologies {
    @include hideScrollbar;
    display: grid;
    justify-content: start;
    grid-template-columns: repeat(4, minmax(120px, 1fr));
    gap: 40px;
    transition: margin 0.6s ease;

    @include media(desktopBig) {
      grid-template-columns: repeat(5, minmax(120px, 1fr));
    }

    @include media(desktopHuge) {
      grid-template-columns: repeat(5, minmax(120px, 1fr));
    }

    @include media(landscape) {
      grid-template-columns: repeat(3, minmax(120px, 1fr));
    }

    @include media(portrait) {
      grid-template-columns: repeat(4, minmax(120px, 1fr));
    }

    @include media(mobile) {
      grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
  }
}

button.row__title {
  position: relative;
  width: 100%;
  @include setProperty(color, var(--secondary-200), var(--secondary-600));
  transition: all 0.3s ease;

  .dropdownIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
  }

  &.active {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    opacity: 1;

    .dropdownIcon {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 9px;

  &__image {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    font-size: 12px;
    line-height: 14px;
    @include setProperty(color, var(--secondary-100), var(--secondary-500));
  }
}
